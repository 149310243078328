<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
      alt="misc-mask"
    />

    <!-- <v-img
      class="misc-tree"
      src="@/assets/images/misc/tree-4.png"
      alt="misc-tree"
    /> -->

    <div class="page-title text-center px-5">
      <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
        <span class="me-2">{{ t('miscellaneous.page_not_found') }}</span>
        <v-icon color="warning">
          {{ icons.mdiAlert }}
        </v-icon>
      </h2>
      <p class="text-sm">
        {{ t('miscellaneous.we_couldnt_find_the_page') }}
      </p>

      <div class="misc-character d-flex justify-center">
        <v-img
          max-width="100"
          :src="appLogo"
          alt="misc-character"
        />
        <!-- src="@/assets/images/3d-characters/error.png" -->
      </div>

      <v-btn
        color="secondary"
        to="/"
        class="mb-4"
      >
        {{ t('miscellaneous.back_to_home') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiAlert } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import themeConfig from '@themeConfig'

export default {
  setup() {
    const { t } = useUtils()

    return {
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiAlert,
      },

      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
